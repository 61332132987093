<template>
  <v-overlay
    :value="show"
    :z-index="zIndex"
  >
    <v-card
      v-click-outside="close"
      width="1300px"
      light
      class="prevent-trigger-update"
      color="#333333"
    >
      <div class="d-flex justify-end py-3 px-5">
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="sd-vehicles-viewer">
        <vehicles
          :search="search"
          @select="closeOnSelect"
        />
      </div>
    </v-card>
  </v-overlay>
</template>
<script>

import modalWindowMixin from "@/mixins/modal-window.mixin";
import Vehicles from "@/components/catalogs/vehicles.vue";
import {catalogValueSelected} from "@/events/statistics";
import catalogModalMixin from "@/mixins/catalog-modal.mixin";

export default {
  components: {Vehicles},
  mixins: [modalWindowMixin, catalogModalMixin],
  methods: {
    close() {
      this.$emit("update:show", false);
    },
    closeOnSelect(item) {
      catalogValueSelected.trigger({code: item.number, item, id: this.id, field: this.field}, this.field)
      this.close();
    },
  },
};
</script>
<style scoped>
.sd-vehicles-viewer {
  height: 85vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
}
</style>
